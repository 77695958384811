import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons

// React icons
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardFooter from "components/Card/CardFooter.jsx"

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx"

import aamir from "assets/img/faces/aamir-badruddin.jpg"
import osama from "assets/img/faces/osama-zaidat.jpg"
import thomas from "assets/img/faces/thomas-wolfe.jpg"
import edgard from "assets/img/faces/edgard-periera.jpg"

class TeamSection extends React.Component {
  render() {
    const { classes } = this.props
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid,
      classes.imgConstrained
    )
    return (
      <div className={classes.section}>
        <div id="about-us">
          <h2 className={classes.title}>
            Our team has decades of industry experience
          </h2>
          <br />
          <div>
            <GridContainer justify="center">
              <GridItem xs={10} sm={7} md={5}>
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={osama} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Osama Zaidat, MD, MS
                    <br />
                    <small className={classes.smallTitle}>President</small>
                  </h4>
                  <CardBody style={{padding: '0px'}}>
                    <p className={classes.description}>
                      Dr. Zaidat has led multiple FDA clinical studies, with
                      IDE, PMA, HDE, and 510k experience
                    </p>
                  </CardBody>
                  {/* <CardFooter className={classes.justifyCenter}>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaTwitter/>
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaInstagram/>
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaFacebook/>
                  </Button>
                </CardFooter> */}
                </Card>
              </GridItem>
              <GridItem xs={10} sm={7} md={5}>
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={edgard} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Edgard Pereira, MD, MBA
                    <br />
                    <small className={classes.smallTitle}>Secretary</small>
                  </h4>
                  <CardBody style={{padding: '0px'}}>
                    <p className={classes.description}>
                      Dr. Pereira is an Endovascular Neuro-Interventionalist with over 20
                      years of experience.
                    </p>
                  </CardBody>
                  {/* <CardFooter className={classes.justifyCenter}>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaTwitter/>
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaLinkedin/>
                  </Button>
                </CardFooter> */}
                </Card>
              </GridItem>
              <GridItem xs={10} sm={7} md={5}>
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={thomas} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Thomas Wolfe, MD
                    <br />
                    <small className={classes.smallTitle}>Vice President</small>
                  </h4>
                  <CardBody style={{padding: '0px'}}>
                    <p className={classes.description}>
                      Dr. Wolfe is an Endovascular Neuro-Interventionalist with more than
                      15 years experience treating strokes.
                    </p>
                  </CardBody>
                  {/* <CardFooter className={classes.justifyCenter}>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaTwitter/>
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaInstagram/>
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaFacebook/>
                  </Button>
                </CardFooter> */}
                </Card>
              </GridItem>
              <GridItem xs={10} sm={7} md={5}>
                <Card plain>
                  <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                    <img src={aamir} alt="..." className={imageClasses} />
                  </GridItem>
                  <h4 className={classes.cardTitle}>
                    Aamir Badruddin, MD, MBA
                    <br />
                    <small className={classes.smallTitle}>CFO</small>
                  </h4>
                  <CardBody style={{padding: '0px'}}>
                    <p className={classes.description}>
                      Dr. Badruddin has over 10 years of experience in Stroke
                      and Endovascular Neurology.
                    </p>
                  </CardBody>
                  {/* <CardFooter className={classes.justifyCenter}>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaTwitter/>
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaInstagram/>
                  </Button>
                  <Button
                    justIcon
                    color="transparent"
                    className={classes.margin5}
                  >
                    <FaFacebook/>
                  </Button>
                </CardFooter> */}
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(teamStyle)(TeamSection)
