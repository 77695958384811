import { compose, converge, prop, objOf, mergeLeft, identity } from 'ramda';
const ENDPOINT = "https://notifications-en4vj7lpuq-uc.a.run.app"
// const ENDPOINT = "http://localhost:3000/"
const CONFIRMATION_HEADERS = {
  'Content-Type': 'application/json',
  "Template-Id": "18554528",
  "From-Email": "info@galaxytherapeutics.com"
};
const NOTIFICATION_HEADERS = {
  'Content-Type': 'application/json',
  "Template-Id": "18611946",
  "From-Email": "no-reply@galaxytherapeutics.com",
  "To-Email": 'info@galaxytherapeutics.com,lusi@galaxytherapeutics.com,lucia@galaxytherapeutics.com'
};
const TEST_HEADERS = {
  'Content-Type': 'application/json',
  "Template-Id": "18611946",
  "From-Email": "no-reply@galaxytherapeutics.com",
  "To-Email": 'wiarda+test@gmail.com'
}

const getFormData = target => new FormData(target);
const getEntries = input => Array.from(input.entries());
const reduceEntries = entries => entries.reduce((acc, [k, v]) => (acc[k] = v, acc), Object.create(null));
const prepareBody = compose(reduceEntries, getEntries, getFormData);
const replyToFromEmail = compose(objOf("ReplyTo"), prop("email"));
const modifyReplyTo = converge(mergeLeft, [identity, replyToFromEmail]);

const buildRequest = headers => valueMap => fetch(ENDPOINT, {
    method: 'POST',
    headers,
    body: JSON.stringify(valueMap)
  });

export const sendConfirmation = compose(buildRequest(CONFIRMATION_HEADERS), prepareBody);
// export const sendNotification = compose(buildRequest(NOTIFICATION_HEADERS), prepareBody);
export const sendNotificationWithReplyTo = compose(buildRequest(NOTIFICATION_HEADERS), modifyReplyTo, prepareBody);
