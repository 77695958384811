import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

import Fade from "@material-ui/core/Fade"
import CircularProgress from "@material-ui/core/CircularProgress"

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"

import workStyle from "assets/jss/material-kit-react/views/landingPageSections/workStyle.jsx"

// submitHandler
import {
  sendNotificationWithReplyTo,
  sendConfirmation,
} from "../../scripts/sendNotificationEmail"

class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
    }
    this.notify = this.notify.bind(this)
  }
  notify(e) {
    e.preventDefault()
    const notify = sendNotificationWithReplyTo(e.target);
    const confirm = sendConfirmation(e.target)

    Promise.all([notify, confirm])
      .then(() => this.setState({ formSubmitted: true }))
      .catch(err => this.setState({ formSubmitted: false }))

    this.setState({ formSubmitted: "pending" })
  }
  render() {
    const { classes } = this.props
    const { formSubmitted } = this.state

    if (formSubmitted === true) {
      return (
        <Fade in={formSubmitted} timeout={1000}>
          <div className={classes.section}>
            <GridContainer justify="center" id="contact-us">
              <GridItem cs={12} sm={12} md={8}>
                <h2 className={classes.title}>Contact Us</h2>
                <h3 className={classes.description}>
                  Thank you for reaching out, we'll get back to you soon!
                </h3>
              </GridItem>
            </GridContainer>
          </div>
        </Fade>
      )
    } else if (formSubmitted === "pending") {
      return (
        <Fade in={formSubmitted} timeout={1000}>
          <div className={classes.section}>
            <GridContainer justify="center" id="contact-us">
              <GridItem cs={12} sm={12} md={8}>
                <h2 className={classes.title}>Contact Us</h2>
                <h3 className={classes.description}>
                  Processing...
                  <br />
                  <br />
                  <CircularProgress />
                </h3>
              </GridItem>
            </GridContainer>
          </div>
        </Fade>
      )
    }

    return (
      <div className={classes.section}>
        <div id="contact-us">
          <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={8}>
              <h2 className={classes.title}>Contact Us</h2>
              <h3 style={{ textAlign: "left" }} className={classes.description}>
                Interested in collaboration? Looking for more information on our
                products?
                <br />
                We'd love to hear from you.
              </h3>
              <br />
              <form onSubmit={this.notify}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={7}>
                    <CustomInput
                      labelText="Name"
                      id="name"
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      inputProps={{
                        name: "name",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{
                        required: true,
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        name: "email",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone"
                      id="phone"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "phone",
                      }}
                    />
                  </GridItem>
                  <CustomInput
                    labelText="Message"
                    id="message"
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                      className: classes.textArea,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      name: "message",
                    }}
                  />
                </GridContainer>
                <GridContainer>
                  <GridItem
                    xs={3}
                    sm={3}
                    md={3}
                    // className={classes.textCenter}
                  >
                    <Button type="submit" color="primary">
                      Send Message
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    )
  }
}

export default withStyles(workStyle)(ContactUs)
