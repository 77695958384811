import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
// import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
// import Typography from "@material-ui/core/Typography"

// @material-ui/icons

// React icons
import { FiMail } from "react-icons/fi"

// core components
import Header from "components/Header/Header.jsx"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import Parallax from "components/Parallax/Parallax.jsx"

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx"

// Sections for this page
import ProductSection from "./Sections/ProductSection.jsx"
import TeamSection from "./Sections/TeamSection.jsx"
import ContactUsSection from "./Sections/ContactUsSection.jsx"

// smooth scrolling
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"

import { DOMAIN } from "../settings"
const site = path => `${DOMAIN}${path}`

// const theme = createMuiTheme({
//   typography: {
//     htmlFontSize: 10,
//   },
// })

const dashboardRoutes = []

class LandingPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props
    return (
      // <ThemeProvider theme={theme}>
        <div>
          <Header
            color="white"
            routes={dashboardRoutes}
            brand="Galaxy Therapeutics"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 400,
              color: "white",
            }}
            {...rest}
          />
          <Parallax filter image={require("assets/img/bg7.jpg")}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <h1 className={classes.title}>Galaxy Therapeutics</h1>
                  <h3>Shaping the frontiers of medicine</h3>
                  <br />
                  <ScrollLink
                    to="contact-us"
                    spy={false}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <Button
                      color="primary"
                      size="lg"
                      href="#contact-us"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      <span style={{ paddingRight: "10px" }}>Contact Us</span>
                      <FiMail />
                    </Button>
                  </ScrollLink>
                </GridItem>
              </GridContainer>
            </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
              <ProductSection />
              <TeamSection />
              <ContactUsSection />
              <div style={{ height: "10px" }} />
            </div>
          </div>
          <div style={{ height: "50px" }} />
          <Footer />
          <div style={{ height: "30px" }} />
        </div>
      // </ThemeProvider>
    )
  }
}

export default withStyles(landingPageStyle)(LandingPage)
