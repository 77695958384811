import React from "react"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// @material-ui/icons
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded"
import LocalHospitalRoundedIcon from "@material-ui/icons/LocalHospitalRounded"
import BeenhereRoundedIcon from "@material-ui/icons/BeenhereRounded"
import Chat from "@material-ui/icons/Chat"
import VerifiedUser from "@material-ui/icons/VerifiedUser"
import Fingerprint from "@material-ui/icons/Fingerprint"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx"

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <div className={classes.section}>
        <div id="product">
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>
                Innovative Neurovascular Devices
              </h2>
              <h3 style={{ textAlign: "left" }} className={classes.description}>
                Over 6 million Americans suffer from unruptured cerebral
                aneurysms. Unfortunately, the devices used to treat cerebral
                aneurysms have just a 50% success rate.
              </h3>
              <h3 style={{ textAlign: "left" }} className={classes.description}>
                Galaxy Therapeutics is developing a treatment device that will
                solve these shortcomings.
              </h3>
              <br />
            </GridItem>
          </GridContainer>
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Easy deployment & fluoroscopic visualization"
                  description=""
                  icon={VisibilityRoundedIcon}
                  iconColor="info"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Improved conformation to aneurysm"
                  description=""
                  icon={BeenhereRoundedIcon}
                  iconColor="success"
                  vertical
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <InfoArea
                  title="Extended treatment for shallow or large aneurysms"
                  description=""
                  icon={LocalHospitalRoundedIcon}
                  iconColor="danger"
                  vertical
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(productStyle)(ProductSection)
