import React from "react"
import { Helmet } from "react-helmet"
import LandingPage from "../Components/LandingPage"
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from "@material-ui/core/styles"
import CssBaseline from '@material-ui/core/CssBaseline';

import styles from "./globalStyles.css"
import Typography from "@material-ui/core/Typography"

let theme = createMuiTheme({
  typography: {
    "fontFamily": `"Helvetica", "Arial", sans-serif`,
  },
  MuiCssBaseline: {
    '@global': {
      body: {
        'font-family': "'Helvetica', 'Arial', sans-serif"
      }
    }
  }
  // htmlFontSize: 50
});

// theme.typography.h3 = {
//     '@media (max-width:600px)': {
//       fontSize: '1.5rem',
//     },
//     [theme.breakpoints.up('md')]: {
//       fontSize: '2.4rem',
//     }
// };

// theme = responsiveFontSizes(theme);

// console.log({ MuiThemeProvider })

export default () => (
  <>
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>Galaxy Therapeutics</title>
      <link rel="canonical" href="https://galaxytherapeutics.com" />
    </Helmet>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <LandingPage />
    </MuiThemeProvider>
  </>
)
