/*eslint-disable*/
import React from "react"
// react components for routing our app without refresh
import { Link } from "gatsby"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons"

// React icons
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx"
import Button from "components/CustomButtons/Button.jsx"

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx"

// smooth scrolling
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"

import { DOMAIN } from "../../settings"
const site = path => `${DOMAIN}/${path}`

function HeaderLinks({ ...props }) {
  const { classes } = props
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {/* <Button
          // href="#product"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        > */}
          <ScrollLink
            className={classes.navLinkNoButton}
            to="product"
            activeClass={classes.highlighted}
            spy={true}
            smooth={true}
            offset={-120}
            duration={500}
          >
            {/* <CloudDownload className={classes.icons} />  */}
            Product
          </ScrollLink>
        {/* </Button> */}
      </ListItem>
      <ListItem className={classes.listItem}>
        {/* <Button
          href="#about-us"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        > */}
          {/* <CloudDownload className={classes.icons} />  */}
          <ScrollLink
            className={classes.navLinkNoButton}
            to="about-us"
            activeClass={classes.highlighted}
            spy={true}
            smooth={true}
            offset={-120}
            duration={500}
          >
            About Us
          </ScrollLink>
        {/* </Button> */}
      </ListItem>
      <ListItem className={classes.listItem}>
        <ScrollLink
          className={classes.navLinkNoButton}
          to="contact-us"
          activeClass={classes.highlighted}
          spy={true}
          smooth={true}
          offset={-120}
          duration={500}
        >
          {/* <Button
            href="#contact-us"
            color="transparent"
            // target="_blank"
            className={classes.navLink}
          > */}
            {/* <CloudDownload className={classes.icons} />  */}
            Contact
          {/* </Button> */}
        </ScrollLink>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href={site("publications")}
          color="transparent"
          className={classes.navLink}
        >
          Publications
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href={site("careers")}
          color="transparent"
          className={classes.navLink}
        >
          Careers
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Menu"
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              All components
            </Link>,
            <a
              href={site('#menu')}
              target="_blank"
              className={classes.dropdownLink}
            >
              Documentation
            </a>,
          ]}
        />
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Tooltip
          id="twitter"
          title="Follow us on Twitter"
          placement={
            typeof window !== "undefined" && window.innerWidth > 959
              ? "top"
              : "left"
          }
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <FaTwitter />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="linkedin"
          title="Follow us on LinkedIn"
          placement={
            typeof window !== "undefined" && window.innerWidth > 959
              ? "top"
              : "left"
          }
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.linkedin.com/"
            target="_blank"
            className={classes.navLink}
          >
            <FaLinkedin />
          </Button>
        </Tooltip>
      </ListItem> */}
    </List>
  )
}

export default withStyles(headerLinksStyle)(HeaderLinks)
